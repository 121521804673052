<template>
  <div>
    <div class="d-flex align-items-center justify-content-between mb-3">
      <div class="d-flex align-items-center">
        <h2 class="mr-3">Update Vendor Baru</h2>
      </div>
    </div>
    <a-card>
      <a-row class="mt-4" :gutter="[32, 32]">
        <a-col :span="24" class="d-flex justify-content-between align-items-center">
          <a-button @click="$router.back()">
            <LeftOutlined />Back
          </a-button>
          <!-- <a-button @click="changeEdittable">
              <span v-if="!editable"> <FormOutlined class="mr-2" />Edit </span>
              <span v-else> <EyeOutlined class="mr-2" />View </span>
            </a-button> -->
        </a-col>
        <a-col :span="24">
          <div>
            <h1 class="text-title">Vendor</h1>
          </div>
          <div>
            <a-row :gutter="[24, 12]">
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">ID (4 HURUF)</p>
                  <a-input placeholder="Masukkan Id Vendor" v-model:value="payload.vendorSign"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Nama</p>
                  <a-input placeholder="Masukkan Nama Vendor" v-model:value="payload.name"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Email</p>
                  <a-input placeholder="Masukkan Email Vendor" v-model:value="payload.email"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Nomor Handphone</p>
                  <a-input placeholder="Masukkan Nomor Handphone" v-model:value="payload.mobileNumber"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Nomor Kantor</p>
                  <a-input placeholder="Masukkan Nomor Kantor" v-model:value="payload.officeNumber"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Penanggung Jawab</p>
                  <a-input placeholder="Masukkan Nama Penanggung Jawab" v-model:value="payload.PIC"></a-input>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Alamat</p>
                  <a-textarea placeholder="Masukkan Alamat Vendor" allow-clear
                    v-model:value="payload.address"></a-textarea>
                </div>
              </a-col>
              <a-col :xs="{ span: 24 }" :sm="{ span: 8 }">
                <div class="w-100">
                  <p style="text-align: left;" class="text-label">Tipe Vendor</p>
                  <a-select placeholder="Pilih Tipe Vendor" class="w-100" v-model:value="payload.type">
                    <a-select-option value="Principal">Principal</a-select-option>
                    <a-select-option value="Distributor">Distributor</a-select-option>
                    <a-select-option value="Partner">Partner</a-select-option>
                    >
                  </a-select>
                </div>
              </a-col>
            </a-row>
          </div>
        </a-col>
        <a-col :span="24">
          <a-row :gutter="[24, 12]">
            <a-col :span="18"></a-col>
            <a-col :span="6" class="d-flex justify-content-end align-items-center" style="gap:15px">
              <a-button @click="clearPayload()" type="primary">Clear</a-button>
              <a-button @click="submitPayload()">Submit</a-button>
            </a-col>
          </a-row>
        </a-col>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { onMounted, ref, createVNode, computed, reactive, onBeforeMount } from 'vue'
import { defineAsyncComponent } from 'vue'
import { useStore } from 'vuex'
import { useRoute, useRouter } from 'vue-router'
import {
  LeftOutlined,
} from '@ant-design/icons-vue'
import { message, Modal, notification, Form } from 'ant-design-vue'
import { getVendorsById, putVendor } from '../../services/axios/modc/vendor'


export default {
  components: { LeftOutlined },
  setup() {
    const route = useRoute()
    const router = useRouter()
    const payload = ref({})
    const fetchData = async () => {
      const { vendor } = await getVendorsById(route.params.id)
      payload.value = vendor
    }
    const clearPayload = () => {
      payload.value = {
        vendorSign: '',
        name: '',
        email: '',
        mobileNumber: '',
        officeNumber: '',
        PIC: '',
        address: '',
        type: '',
      }
    }

    const submitPayload = async () => {
      const data = await putVendor(payload.value, route.params.id)
      if (data) {
        notification.success({
          message: 'Berhasil Update',
          description: 'Data Vendor Berhasil di Update',
        })
      } else {
        notification.error({
          message: 'Server Error',
          description: 'Internal Server Error',
        })
      }
      router.push('/vendors')
    }
    onMounted(async () => {
      await fetchData()
    })
    return {
      payload,
      clearPayload,
      submitPayload,
      route,
    }
  },
}
</script>
<style lang="scss">
@import '../styleViews.scss';

.text-title {
  font-size: 1.5rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-subtitle {
  font-size: 1.3rem;
  color: $black;
  font-weight: 500;
  margin-bottom: 10px;
}

.text-label {
  font-size: 1rem;
  color: $text;
  font-weight: 400;
  margin-bottom: 10px;
}

// . {
//   background-color: white !important;
//   input {
//     background-color: white !important;
//   }
// }
// :global(.ant-select-.ant-select:not(.ant-select-customize-input)) {
//   :global(.ant-select-selector) {
//     background-color: white !important;
//   }
// }
// .ant-input .ant- {
//   background-color: #ffffff !important;
// }
.th-upload-wrapper {
  .ant-upload-picture-card-wrapper {
    display: flex;
    justify-content: center;

    .ant-upload {
      width: 300px;
      height: 100px;
    }
  }
}
</style>
